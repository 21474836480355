import { Link } from 'gatsby';
import React from 'react'

const TourPreviewCard = ({ title, img, subtitle, slug }) => {
  return (
    <Link
      to = { slug }
    >
      <div className='group aspect-w-16 aspect-h-9 cursor-pointer'>
          <img
            className='object-cover' 
            src={img} alt="" />
          <div className="absolute top-0 left-0 right-0 bottom-0 h-full group-hover:bg-black group-hover:bg-opacity-30 transition-all flex items-center justify-end flex-col overflow-hidden">
            <h5 className='text-lg font-bold text-neutral-100 text-center translate-y-full group-hover:-translate-y-full transition-all'>{ title }</h5>
            <p className='text-neutral-100 hidden group-hover:block group-hover:-translate-y-4 transition-all'>{ subtitle }</p>
          </div>
      </div>
    </Link>
  );
}
 
export default TourPreviewCard;