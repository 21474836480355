import React from 'react'
import { BASE_URL } from '../assets/js/_variables';

const WhyCard = ({ icon, title, text }) => {
  return (
    <div className='text-center p-4 flex items-center justify-between flex-col'>
      <img 
        className='mx-auto mb-6'
        src={`${BASE_URL}${icon}`} alt="" 
      />
      <h5 className='text-neutral-800 font-bold text-xl'>{ title }</h5>
      <p className='normal-text'>{ text }</p>
    </div>
  );
}
 
export default WhyCard;