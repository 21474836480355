import React, { useState, useEffect } from 'react'
import { BASE_URL } from '../assets/js/_variables';
import FeaturedCard from '../components/FeaturedCard';

// Componentes
import Layout from '../components/Layout'
import Slider from '../components/Slider'
import TourPreviewCard from '../components/TourPreviewCard';
import WhyCard from '../components/WhyCard';

// React funcion
const Home = () => {
  const [lang, setLang] = useState()

  const heroImages = [
    `${BASE_URL}/img_principal.jpg`, `${BASE_URL}/sliders/BARCO-PIRATA-ING.png`, `${BASE_URL}/sliders/TOUR-DE-LA-CIUDAD-ING.png`, `${BASE_URL}/sliders/MARIETAS.png`, `${BASE_URL}/sliders/RITMOS-DE-LA-NOCHE-ING.png`
  ]
  const heroeImagenes = [
    `${BASE_URL}/img_principal_esp.jpg`, `${BASE_URL}/sliders/BARCO-PIRATA-ESP.png`, `${BASE_URL}/sliders/TOUR-DE-LA-CIUDAD-ESP.png`, `${BASE_URL}/sliders/MARIETAS.png`, `${BASE_URL}/sliders/RITMOS-DE-LA-NOCHE-ESP.png`
  ]
  const tours = [
    { title: 'SEA, SUN, LAND TEQUILA & HIKING TOUR', titulo: 'TOUR MAR, SOL, TIERRA TEQUILA & HIKING', img: '/tequila.jpg', slug: '/tours/tequila' },
    { 
      title: 'RHYTHMS OF THE NIGHT',
      titulo: 'RITMOS DE LA NOCHE',
      img: '/night.png',
      slug: '/tours/night' 
    },
    { 
      title: 'WHALE WATCHING',
      titulo: 'AVISTAMIENTO DE BALLENAS',
      img: '/whales.jpg',
      slug: '/tours/whales' 
    },
    { 
      title: 'CANOPY RIVER ZIP LINE',
      titulo: 'TIROLESA DEL RÍO CANOPY',
      img: '/canopy.jpg',
      slug: '/tours/canopy'
    },
    { 
      title: 'AKALI',
      titulo: 'AKALI',
      img: '/AKALI.jpeg',
      slug: '/tours/akali'
    },
    { 
      title: 'E BIKE & TACO TOUR',
      titulo: 'TOUR EN BICICLETA ELÉCTRICA Y TACO',
      img: '/bike.jpg',
      slug: '/tours/bike'
    },
    { 
      title: 'MARIGALANTE PIRATE SHIP',
      titulo: 'BARCO PIRATA MARIGALANTE',
      img: '/barcopirata.jpg',
      slug: '/tours/marigalante-pirate-ship'
    },
    { 
      title: 'SWIM WITH DOLPHINS AT AQUAVENTURAS',
      titulo: 'NADO CON DELFINES EN AQUAVENTURAS',
      img: '/nadardelfines.jpg',
      slug: '/tours/dolphins'
    },
    { 
      title: 'BAHIA ALEGRE MARIETAS',
      titulo: 'BAHIA ALEGRE MARIETAS',
      img: '/marietas.jpg',
      slug: '/tours/marietas'
    },


    { 
      title: 'HORSE BACK RIDING',
      titulo: 'PASEO A CABALLO',
      img: '/horse-back-riding.jpg',
      slug: '/tours/horse-back-riding'
    },
    { 
      title: 'FISHING',
      titulo: 'PESCA',
      img: '/fishing.jpg',
      slug: '/tours/fishing'
    },
    { 
      title: 'TEATRO VALLARTA MAYAHUEL',
      titulo: 'TEATRO VALLARTA MAYAHUEL',
      img: '/teatro-vallarta-mayahuel.jpg',
      slug: '/tours/teatro-vallarta-mayahuel'
    },
    { 
      title: 'JET SKIES TB TOURS',
      titulo: 'JET SKIES TB TOURS',
      img: '/jet-skies-tb-tours.jpg',
      slug: '/tours/jet-skies-tb-tours'
    },


    { 
      title: 'UNIQUE ATVs & RZRs',
      titulo: 'UNIQUE ATVs & RZRs',
      img: '/unique-atvs-&-rzrs.jpg',
      slug: '/tours/unique-atvs-&-rzrs'
    },
    { 
      title: 'HIDDEN BEACH AT MARIETAS ISLANDS',
      titulo: 'PLAYA ESCONDIDA EN ISLAS MARIETAS',
      img: '/hidden-beach-at-marietas-islands.jpg',
      slug: '/tours/hidden-beach-at-marietas-islands'
    },
    { 
      title: 'COOKING CLASSES',
      titulo: 'CLASES DE COCINA',
      img: '/cooking-classes.jpg',
      slug: '/tours/cooking-classes'
    },
    { 
      title: 'DIVING',
      titulo: 'BUCEO',
      img: '/diving.jpg',
      slug: '/tours/diving'
    },
  ]

  const txt = {
    en: {
      featuredTitle: 'Featured Tours',
      featuredText: 'Check out our most popular tours of the month',
      featuredCard1: 'MARIGALANTE PIRATE SHIP',
      featuredCard2: 'BAHIA ALEGRE MARIETAS',
      featuredCard3: 'SWIM WITH DOLPHINS AT AQUAVENTURAS',
      featuredCardBtn: 'Book this tour',
      toursTitle: 'Tours for the whole family',
      toursText: 'Our tours are made so that all members of the family have fun.', 
      whyTitle: 'Why choose our tours?',
      whySub: 'We offer you the guarantee that we only work with the best tour companies in PV, that comply with all the safety and hygienic regulations required and that have friendly cancellation policies in case the unexpected happens. Buenaventura Hotels and HB Tours endorse our providers.',
      whyCards: [
        { title: 'Unique Destinations', text: 'Looking for a unique vacation destination? Then maybe a trip to one of our fantastic tours might be what your looking for.', icon: '/mapa.png' },
        { title: 'COVID-19 Standards', text: 'We follow safety standards so you can enjoy your trip without worries.', icon: '/mascarilla.png' },
        { title: 'Wonderful Places', text: 'We do our best to have you a wonderful experience by taking you to the wonderful and amazing places in Puerto Vallarta.', icon: '/locacion.png' },
      ],
    },
    es : {
      featuredTitle: 'Tours Destacados',
      featuredText: 'Echa un vistazo a nuestros tours más populares del mes',
      featuredCard1: 'BARCO PIRATA MARIGALANTE',
      featuredCard2: 'BAHIA ALEGRE MARIETAS',
      featuredCard3: 'NADO CON DELFINES EN AQUAVENTURAS',
      featuredCardBtn: 'Reserva este tour',
      toursTitle: 'Tours para toda la familia',
      toursText: 'Nuestros tours están hechos para que todos los miembros de la familia se diviertan.',
      whyTitle: '¿Por qué elegir nuestros tours?',
      whySub: 'Le ofrecemos la garantía de que solo trabajamos con las mejores compañías de tours en Puerto Vallarta que cumplen con todos los requerimientos de higiene y seguridad y que ofrecen políticas amigables de cancelación, en caso de que lo inesperado ocurra. Hoteles Buenaventura y HB Tours respaldan a nuestros proveedores.',
      whyCards: [
        { title: 'Destinos Únicos', text: '¿Buscas un destino vacacional único? Entonces, tal vez un viaje a uno de nuestros fantásticos tours sea lo que estás buscando.', icon: '/mapa.png'  },
        { title: 'Estándares COVID-19', text: 'Seguimos las normas de seguridad para que puedas disfrutar de tu viaje sin preocupaciones.', icon: '/mascarilla.png' },
        { title: 'Lugares Maravillosos', text: 'Hacemos todo lo posible para que tengas una experiencia maravillosa llevándote a los lugares maravillosos y sorprendentes de Puerto Vallarta.', icon: '/locacion.png' },
      ],
    }
  }



  
  // Funciones
  const getLang = () => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('lang')
    }
  }

  useEffect(() => {
    if(getLang() === 'es') {
      setLang('es')
    } else {
      setLang('en')
    }
  }, [])

  return (
    <Layout pageTitle='HB Privileged' className='scroll-smooth'>
      <div className="">
        <Slider images={ lang === 'es' ? heroeImagenes : heroImages } isHero={true} />
      </div>

      {/* Featured Tours */}
      <div className="bg-neutral-200 pb-4">
        <div className="section pt-8 md:pt-16">
          <h2 className='title mb-4'>
            { lang === 'es' ? txt.es.featuredTitle : txt.en.featuredTitle }
          </h2>
          <p className="normal-text text-center mb-4">
            { lang === 'es' ? txt.es.featuredText : txt.en.featuredText }
          </p>
        </div>

        {/* Featured Cards */}
        <div className="section">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
            <FeaturedCard
              slug = {`${BASE_URL}/`}
              title = { lang === 'es' ? txt.es.featuredCard1 : txt.en.featuredCard1 }
              image = {`${BASE_URL}/marigalante.jpg`}
              exUrl = {`${BASE_URL}/contacto`}
              btnText = { lang === 'es' ? txt.es.featuredCardBtn : txt.en.featuredCardBtn }
              urlCard = {`${BASE_URL}/tours/marigalante-pirate-ship`}
            />
            <FeaturedCard
              slug = {`${BASE_URL}/`}
              title = { lang === 'es' ? txt.es.featuredCard2 : txt.en.featuredCard2 }
              image = {`${BASE_URL}/marietas.jpg`}
              exUrl = {`${BASE_URL}/contacto`}
              btnText = { lang === 'es' ? txt.es.featuredCardBtn : txt.en.featuredCardBtn }
              urlCard = {`${BASE_URL}/tours/marietas`}
            />
            <FeaturedCard
              slug = {`${BASE_URL}/contacto`}
              title = { lang === 'es' ? txt.es.featuredCard3 : txt.en.featuredCard3 }
              image = {`${BASE_URL}/delfines.jpg`}
              exUrl = {`${BASE_URL}/contacto`}
              btnText = { lang === 'es' ? txt.es.featuredCardBtn : txt.en.featuredCardBtn }
              urlCard = {`${BASE_URL}/tours/whales`}
            />
          </div>
        </div>
      </div>

      {/* Tours */}
      <div className="bg-neutral-50 py-8 lg:py-16" id="tours">
        <h2 className='title mb-4'>
          { lang === 'es' ? txt.es.toursTitle : txt.en.toursTitle }
        </h2>
        <p className="normal-text text-center mb-4">
          { lang === 'es' ? txt.es.toursText : txt.en.toursText }
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
          {
            tours && tours.map(tour => (
              <TourPreviewCard
                slug = { tour.slug }
                title = { lang === 'es' ? tour.titulo : tour.title }
                subtitle = { lang === 'es' ? 'Más info' : 'More info' }
                img = {`${BASE_URL}${tour.img}`}
              />
            ))
          }
        </div>
      </div>

      {/* Why choose us */}
      <div className="bg-neutral-200 py-4">
        <div className="section">
          <h2 className='title mb-4'>
            { lang === 'es' ? txt.es.whyTitle : txt.en.whyTitle }
          </h2>
          <p className="normal-text text-center mb-4">
            { lang === 'es' ? txt.es.whySub : txt.en.whySub }
          </p>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 md:gap-4 py-4">
            {
              lang === 'es'
              ? (
                txt.es.whyCards.map(card => (
                  <WhyCard
                    icon={card.icon}
                    title={card.title}
                    text={card.text}
                  />
                ))
              )
              : (
                txt.en.whyCards.map(card => (
                  <WhyCard
                    icon={card.icon}
                    title={card.title}
                    text={card.text}
                  />
                ))
              )
            }
          </div>
        </div>
      </div>
      
    </Layout>
  );
}
 
export default Home;
