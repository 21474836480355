import React from 'react'
import { Link } from 'gatsby';

const FeaturedCard = ({ slug, title, image, btnText, exUrl, urlCard }) => {
  return (
    <div>
      <div className='group relative overflow-hidden rounded-lg bg-neutral-50 shadow-2xl'>
        <div className='aspect-w-3 aspect-h-4'>
          <img 
            className='object-cover'
            src={image} alt="" />
        </div>
        <Link
          to={urlCard}
        >
          <div className="group absolute top-0 left-0 right-0 bottom-0 cursor-pointer group-hover:bg-black group-hover:bg-opacity-30 transition duration-300 flex justify-center p-4">
            <h5 className='text-neutral-50 font-bold text-2xl text-center'>{ title }</h5>
          </div>
        </Link>
      </div>

      <div className='mt-4 relative'>
        {/* Featured Tours 
        <a 
          href={exUrl}
          className='w-full block text-center bg-sky-800 text-neutral-50 py-2 px-4 rounded-full' 
          target="_blank" 
          rel="noopener noreferrer"
        >*/}
        <Link
          to={exUrl}
        
          className='w-full block text-center bg-sky-800 text-neutral-50 py-2 px-4 rounded-full' 
          target="_blank" 
          rel="noopener noreferrer"
        >
          { btnText }
        </Link>


          {/*{ btnText }*/}
        {/*</a>*/}
      </div>
    </div>
  );
}
 
export default FeaturedCard;